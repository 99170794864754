import { css } from "styled-components";

const BREAKPOINTS = {
  xxxxs: 250,
  xxxs: 375,
  xxs: 390,
  xs: 480,
  sm: 767,
  md: 992,
  lg: 1024,
  xl: 1200,
  xxl: 1600,
  xxxl: 1800,
};

const IPADBREAKPOINTS = {
  AIR: {
    width: 820,
    height: 1180,
  },
  PRO: {
    width: 1024,
    height: 1366,
  },
  CURRENT: {
    width: 810,
    height: 1080,
  },
  STANDARD: {
    width: 768,
    height: 1024,
  },
};

const generateMediaQueries = (breakpoints) => {
  const queryParts = Object.keys(breakpoints).map((key) => {
    const bp = breakpoints[key];
    return `screen and (device-width: ${bp.width}px) and (device-height: ${bp.height}px)`;
  });
  return `@media ${queryParts.join(", ")}`;
};

const hiddenxl = css`
  @media (max-width: ${BREAKPOINTS.xl}px) {
    display: none;
  }
`;

const hiddenlg = css`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`;

const hiddenmd = css`
  @media (max-width: ${BREAKPOINTS.md}px) {
    display: none;
  }
`;

const hiddensm = css`
  @media (max-width: ${BREAKPOINTS.sm}px) {
    display: none;
  }
`;

const hiddenxs = css`
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: none;
  }
`;

const hiddenxxs = css`
  @media (max-width: ${BREAKPOINTS.xxs}px) {
    display: none;
  }
`;

const hiddenxxxs = css`
  @media (max-width: ${BREAKPOINTS.xxxs}px) {
    display: none;
  }
`;

const desktop = css`
  @media (max-width: ${BREAKPOINTS.sm - 1}px) {
    display: none;
  }
`;

const ipad = css`
  @media (min-width: ${BREAKPOINTS.md}px) {
    display: none;
  }
`;

const mobile = css`
  @media (min-width: ${BREAKPOINTS.sm}px) {
    display: none;
  }
`;

const mobileXs = css`
  @media (min-width: ${BREAKPOINTS.xs}px) {
    display: none;
  }
`;

export {
  BREAKPOINTS,
  IPADBREAKPOINTS,
  generateMediaQueries,
  hiddenxl,
  hiddenlg,
  hiddenmd,
  hiddensm,
  hiddenxs,
  hiddenxxs,
  hiddenxxxs,
  desktop,
  ipad,
  mobile,
  mobileXs,
};
