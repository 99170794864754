export const COLOURS = {
  primary: {
    black: "#000000",
    blue: "#0019FF",
    aqua: "#00E0FF",
    white: "#FFFFFF",
    yellow: "#FFE600",
    red: "#810808",
    grey: "#999999",
  },
};

export const BORDERS = {
  primary: "rgba(255, 255, 255, 0.4)",
  secondary: "rgba(0, 0, 0, 0.4)",
};

export const BACKGROUNDS = {
  primary: "linear-gradient(180deg, #000 0%, #FFF 50%)",
};
