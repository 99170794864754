import React from "react";
import { COLOURS } from "../styles/variables/Colors";
import { noSelect } from "../styles/variables/Fonts";
import { Global, css } from "@emotion/react";

const GlobalStyles = () => (
  <Global
    styles={css`
      html,
      body {
        &.disable-scroll {
          overflow: hidden;
        }
        overscroll-behavior: none;
      }
      body {
        background-color: ${COLOURS.primary.black};
        height: 100vh;
        margin: 0;
        padding: 0;
        font-size: 16px;
        &::-webkit-scrollbar {
          display: none;
        }
        ::-webkit-scrollbar {
          display: none;
        }
      }

      #app {
        width: 100%;
        height: 100lvh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        &.glitch {
          z-index: 999;
        }
      }

      .dg.ac {
        z-index: 999 !important;
      }

      #___gatsby {
        margin: 0;
        font-size: 18px;
        font-family: "univers-light", sans-serif;
        line-height: 1.4em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow-x: hidden;
        color: rgba(33, 23, 3, 1);
        box-sizing: border-box;
        margin: 0;
      }

      a,
      a:visited {
        text-decoration: none;
      }

      p,
      a,
      h1,
      h2,
      h3,
      h4 {
        ${noSelect};
      }

      button {
        padding: 0px;

        &:hover,
        &:focus {
          outline: none;
        }
      }

      img {
        max-width: 100%;
      }
    `}
  />
);

export default GlobalStyles;
