import { css } from "styled-components";
import { BREAKPOINTS } from "./Breakpoints";

const TEXT_PIXELS = {
  xxxxs: 11,
  xxxs: 11,
  xxs: 13,
  xs: 16,
  sm: 16,
  md: 18,
  lg: 20,
  xl: 20,
  xxl: 22,
  xxxl: 25,
};

function calculatePx(remSize, breakpoint) {
  return `${(parseFloat(remSize) * TEXT_PIXELS[breakpoint]).toFixed(2)}px`;
}

function generateFont(
  remSize,
  shouldGenerateMediaQueries = true,
  specificBreakpoint = "md"
) {
  return generateRule(
    remSize,
    "font-size",
    shouldGenerateMediaQueries,
    specificBreakpoint
  );
}

function generateLineHeight(
  remSize,
  shouldGenerateMediaQueries = true,
  specificBreakpoint = "md"
) {
  return generateRule(
    remSize,
    "line-height",
    shouldGenerateMediaQueries,
    specificBreakpoint
  );
}

function generateRule(
  remSize,
  property,
  shouldGenerateMediaQueries = true,
  specificBreakpoint = "md"
) {
  if (shouldGenerateMediaQueries) {
    const rules = [];
    for (const breakpoint in BREAKPOINTS) {
      const mediaQuery = `(min-width: ${BREAKPOINTS[breakpoint]}px)`;
      rules.push(
        `@media ${mediaQuery} {
          ${property}: ${calculatePx(remSize, breakpoint)};
        }`
      );
    }
    return rules.join("\n");
  } else {
    return `${property}: ${calculatePx(remSize, specificBreakpoint)};`;
  }
}

const noSelect = css`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export { noSelect, generateLineHeight, generateFont };
